import emissorNotas from "../assets/icons-segmento/emissao-notas.svg";
import controlerEstoque from "../assets/icons-segmento/controler-estoque.svg";
import controlerFinanceiro from "../assets/icons-segmento/controler-financeiro.svg";
import ordemServicos from "../assets/icons-segmento/ordem-servicos.svg";
import frenteCaixa from "../assets/icons-segmento/frente-caixa.svg";

import imagemContato from "../assets/imagem-contato.svg";
const Distribuidora = () => {
  return (
    <section className="section-segmento">
      <div className="image-banner" id="distribuidora">
        <h1>Distribuidora</h1>
      </div>
      <div className="descricao-segmento">
        <h2>TECNOLOGIA PARA O SEU NEGÓCIO</h2>
        <p>
          Bem-vindo à nossa seção dedicada à Gestão Comercial no segmento de
          Distribuidoras. Aqui, oferecemos soluções especializadas para aumentar
          a eficiência e o desempenho da sua distribuidora, seja ela de pequeno
          ou grande porte. Nossa plataforma de software foi projetada para
          atender às necessidades específicas do setor de distribuição,
          oferecendo ferramentas avançadas que facilitam a gestão de estoque,
          controle de pedidos, logística e relacionamento com fornecedores e
          clientes. Com nossas soluções, você pode otimizar seus processos e
          garantir que suas operações ocorram de maneira ágil, precisa e
          lucrativa.
        </p>
      </div>
      <div className="itens-segmento">
        <div className="item-segmento">
          <img
            src={controlerEstoque}
            alt="emissor notas icon"
            width="80"
            height="80"
          />
          <p className="titulo-item">Controle de estoque</p>
          <p className="descricao-item">
            O módulo de controle de estoque oferece uma gestão eficiente dos
            seus produtos, permitindo o acompanhamento em tempo real das
            entradas e saídas. Mantenha seu estoque otimizado, evitando excessos
            ou faltas, e ganhe agilidade na reposição de mercadorias.
          </p>
        </div>
        <div className="item-segmento">
          <img
            src={ordemServicos}
            alt="emissor notas icon"
            width="80"
            height="80"
          />
          <p className="titulo-item">Ordem de serviços</p>
          <p className="descricao-item">
            Agilize o processo de atendimento ao cliente com o sistema de ordens
            de serviço. Atribua tarefas, acompanhe o status das atividades e
            garanta um serviço mais organizado. Ganhe em produtividade e
            satisfação do cliente.
          </p>
        </div>
        <div className="item-segmento">
          <img
            src={controlerFinanceiro}
            alt="emissor notas icon"
            width="80"
            height="80"
          />
          <p className="titulo-item">Controle financeiro</p>
          <p className="descricao-item">
            Tenha o controle total das finanças da sua empresa. Com o módulo de
            controle financeiro, acompanhe receitas, despesas e fluxo de caixa
            de forma intuitiva. Tomadas de decisão mais seguras e uma gestão
            financeira eficiente.
          </p>
        </div>
        <div className="item-segmento">
          <img
            src={emissorNotas}
            alt="emissor notas icon"
            width="80"
            height="80"
          />
          <p className="titulo-item">Emissão de notas</p>
          <p className="descricao-item">
            Simplifique a emissão de notas fiscais com nosso software. Agilidade
            e conformidade fiscal garantidas. Emita notas fiscais eletrônicas de
            forma rápida e segura, atendendo a todas as obrigações legais.
          </p>
        </div>
        <div className="item-segmento">
          <img
            src={frenteCaixa}
            alt="emissor notas icon"
            width="80"
            height="80"
          />
          <p className="titulo-item">Frente de caixa</p>
          <p className="descricao-item">
            O módulo de frente de caixa proporciona uma experiência de compra
            rápida e eficiente. Realize vendas, receba pagamentos e emita
            comprovantes de forma integrada. Otimize o atendimento ao cliente e
            proporcione uma experiência de compra mais fluida.
          </p>
        </div>
      </div>

      <div className="contato-item">
        <div className="container-contato-item">
          <div className="descricao-contato">
            <p>
              Prepare o seu negócio para as exigências do{" "}
              <span class="easy-color">mercado</span>
            </p>
            <div className="contato-link">
              <a
                href="https://api.whatsapp.com/send?phone=+559498110-8003&amp;text=Olá tudo bem?"
                target="_blank"
                rel="noreferrer"
              >
                Contato
              </a>
            </div>
          </div>
          <div className="imagem-contato">
            <img
              src={imagemContato}
              alt="imagem contaot"
              width="300"
              height="300"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Distribuidora;
