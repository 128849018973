import React, { useEffect, useState } from "react";
import { Link, Events, scrollSpy } from "react-scroll";
import { FiAlignRight, FiChevronDown, FiXCircle } from "react-icons/fi";
import logo from "../../assets/logo.svg";
import { NavLink } from "react-router-dom";

const Navbarmenu = () => {
  const [isMenu, setisMenu] = useState(false);
  const [isResponsiveclose, setResponsiveclose] = useState(false);
  const toggleClass = () => {
    setisMenu(isMenu === false ? true : false);
    setResponsiveclose(isResponsiveclose === false ? true : false);
  };

  let boxClass = ["main-menu menu-right menuq1"];
  if (isMenu) {
    boxClass.push("menuq2");
  } else {
    boxClass.push("");
  }

  const [isMenuSubMenu, setMenuSubMenu] = useState(false);

  const toggleSubmenu = () => {
    setMenuSubMenu(isMenuSubMenu === false ? true : false);
  };

  let boxClassSubMenu = ["sub__menus"];
  if (isMenuSubMenu) {
    boxClassSubMenu.push("sub__menus__Active");
  } else {
    boxClassSubMenu.push("");
  }

  useEffect(() => {
    Events.scrollEvent.register("begin", (to, element) => {
      console.log("begin", to, element);
    });

    Events.scrollEvent.register("end", (to, element) => {
      console.log("end", to, element);
    });

    scrollSpy.update();

    return () => {
      Events.scrollEvent.remove("begin");
      Events.scrollEvent.remove("end");
    };
  }, []);

  return (
    <header className="header__middle">
      <div className="container">
        {/* Add Logo  */}
        <div className="header__middle__logo">
          <Link exact activeClassName="is-active" to="/">
            <img src={logo} alt="logo" />
          </Link>
        </div>

        <div className="header__middle__menus">
          <nav className="main-nav ">
            {/* Responsive Menu Button */}
            {isResponsiveclose === true ? (
              <>
                <span
                  className="menubar__button"
                  style={{ display: "none" }}
                  onClick={toggleClass}
                >
                  {" "}
                  <FiXCircle />{" "}
                </span>
              </>
            ) : (
              <>
                <span
                  className="menubar__button"
                  style={{ display: "none" }}
                  onClick={toggleClass}
                >
                  {" "}
                  <FiAlignRight />{" "}
                </span>
              </>
            )}

            <ul className={boxClass.join(" ")}>
              <li className="menu-item">
                <NavLink
                  exact
                  activeClassName="is-active"
                  onClick={toggleClass}
                  to={`/`}
                >
                  {" "}
                  Home{" "}
                </NavLink>
              </li>
              <li className="menu-item ">
                <Link
                  activeClass="active"
                  to="sobre"
                  spy={true}
                  smooth={true}
                  // offset={50}
                  duration={500}
                >
                  Sobre
                </Link>
              </li>

              <li className="menu-item ">
                <Link
                  activeClass="active"
                  to="segmento"
                  spy={true}
                  smooth={true}
                  offset={-50}
                  duration={500}
                >
                  Segmentos
                </Link>
              </li>
              {
                <li
                  onClick={toggleSubmenu}
                  className="menu-item  sub__menus__arrows"
                >
                  {" "}
                  <Link to="#">
                    {" "}
                    Login <FiChevronDown />{" "}
                  </Link>
                  <ul className={`sub-item-menu ${boxClassSubMenu.join(" ")}`}>
                    <li>
                      {" "}
                      <NavLink
                        onClick={toggleClass}
                        activeClassName="is-active"
                        to={`/`}
                      >
                        {" "}
                        Representante{" "}
                      </NavLink>{" "}
                    </li>
                    <li>
                      <NavLink
                        onClick={toggleClass}
                        activeClassName="is-active"
                        to={`/`}
                      >
                        {" "}
                        Contador{" "}
                      </NavLink>{" "}
                    </li>
                    <li>
                      <NavLink
                        onClick={toggleClass}
                        activeClassName="is-active"
                        to={`/`}
                      >
                        {" "}
                        NFe{" "}
                      </NavLink>{" "}
                    </li>
                  </ul>
                </li>
              }
              <li className="menu-item contato-menu">
                <NavLink
                  onClick={toggleClass}
                  activeClassName="is-active"
                  to={`https://api.whatsapp.com/send?phone=+559498110-8003&amp;text=Olá tudo bem?`}
                  target="_blank"
                >
                  {" "}
                  Contato{" "}
                </NavLink>{" "}
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </header>
  );
};

export default Navbarmenu;
