// App.js
import "./App.css";
// import { NavLink } from "react-router-dom";

import Routes from "./routes";
import Navbarmenu from "./components/menu/nav_bar_menu";
import Footer from "./components/footer/footer";

function App() {
  return (
    <div className="App">
      {/* <div>
        <NavLink to="/">Home</NavLink>
        <NavLink to="/about">About</NavLink>
        <NavLink to="/user">User</NavLink>
      </div> */}
      <Navbarmenu />
      <Routes />
      <Footer />
    </div>
  );
}

export default App;
