// Routes.js
import { Route, Routes as RoutesDom } from "react-router-dom";

import Home from "./components/home";
import VarejoAtacado from "./pages/varejo-atacado";
import Restaurante from "./pages/restaurante";
import Farmacia from "./pages/farmacia";
import AutoPeca from "./pages/auto-pecas";
import Supermercado from "./pages/supermercado";
import Padaria from "./pages/padaria";
import Distribuidora from "./pages/distruibuidora";
import MaterialConstrucao from "./pages/materialConstrucao";
import Otica from "./pages/otica";
const Routes = () => {
  return (
    <RoutesDom>
      <Route index element={<Home />} />
      <Route path="/varejo-atacado" element={<VarejoAtacado />} />
      <Route path="/restaurante" element={<Restaurante />} />
      <Route path="/farmacia" element={<Farmacia />} />
      <Route path="/supermercado" element={<Supermercado />} />
      <Route path="/auto-peca" element={<AutoPeca />} />
      <Route path="/padaria" element={<Padaria />} />
      <Route path="/distribuidora" element={<Distribuidora />} />
      <Route
        path="/material-para-construcao"
        element={<MaterialConstrucao />}
      />
      <Route path="/otica" element={<Otica />} />
    </RoutesDom>
  );
};

export default Routes;
