import marcaAgua from "../../assets/marca-agua.png";

import whatsApp from "../../assets/whatsapp.png";
import instagram from "../../assets/instagram.png";
import email from "../../assets/email.png";
const Footer = () => {
  return (
    <footer>
      <div className="container-footer">
        <div className="logo-footer">
          <img src={marcaAgua} alt="logo" />
        </div>
        <div className="descricao-footer">
          <p>© 2023 EASY GESTÃO COMERCIAL | TODOS OS DIREITOS RESERVADOS</p>
        </div>
        <div className="contato-footer">
          <div className="container-icones">
            <div className="icones">
              <div>
                <a
                  href="https://api.whatsapp.com/send?phone=+559498110-8003&amp;text=Olá tudo bem?"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={whatsApp} alt="whatsapp" width="20" height="20" />
                </a>
              </div>
              <div>
                <a href="mailto:contato@easygestao.tech">
                  <img
                    src={email}
                    alt="email"
                    width="20"
                    height="20"
                    target="_blank"
                  />
                </a>
              </div>
              <div>
                <a
                  href="https://www.instagram.com/easygestaocomercial/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={instagram} alt="instagram" width="20" height="20" />
                </a>
              </div>
            </div>
            <p>Entre em contato</p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
