import React, { useEffect, useState } from "react";
import setas from "../assets/setas.svg";
import logoGrande from "../assets/imagem-grande.svg";
import iconCheck from "../assets/check.svg";

import construcao from "../assets/servicos/construcao.svg";
import oculos from "../assets/servicos/oculos.svg";
import distribuidora from "../assets/servicos/distribuidora.svg";
import padaria from "../assets/servicos/padaria.svg";
import restaurante from "../assets/servicos/restaurante.svg";
import auto from "../assets/servicos/auto.svg";
import varejo from "../assets/servicos/varejo.svg";
import farmacia from "../assets/servicos/farmacia.svg";
import supermercado from "../assets/servicos/supermercado.svg";

import { Element } from "react-scroll";

const Home = () => {
  const [numeros] = useState([
    { nome: "Cidades", final: 14 },
    { nome: "Estados", final: 2 },
    { nome: "Clientes", final: 120 },
    { nome: "De experiência", final: 8 },
  ]);
  useEffect(() => {
    const animarNumeros = () => {
      const elementosNumero = document.querySelectorAll(".numero-item");

      elementosNumero.forEach((elemento, index) => {
        const valorFinal = parseInt(elemento.getAttribute("data-valor-final"));
        const incremento = 1;
        const intervalo = 100;

        let numeroAtual = 0;

        const animacao = setInterval(() => {
          elemento.textContent = numeroAtual;
          numeroAtual += incremento;

          if (numeroAtual > valorFinal) {
            clearInterval(animacao);
          }
        }, intervalo);
      });
    };

    animarNumeros();

    return () => {
      const elementosNumero = document.querySelectorAll(".numero-item");
      elementosNumero.forEach((elemento) => {
        elemento.textContent = 0;
      });
    };
  }, []); // O array va
  return (
    <>
      <main className="banner-inicial" id="home">
        <div className="espaco"></div>
        <h1>
          <span className="easy-color">Easy</span> Gestão Comercial
        </h1>
        <p>
          A Easy Gestão Comercial é uma empresa especializada em Desenvolvimento
          de sistemas que busca sempre oferecer qualidade, agilidade e
          eficiência em Softwares para sua Empresa.
        </p>
        <img src={setas} alt="seta" width="40" height="100" />
      </main>

      <section className="segundo-banner">
        <div className="left">
          <h2>
            Como vamos <span className="easy-color">auxiliar</span>
          </h2>
          <p>
            Na era moderna dos negócios, ter o controle total sobre a sua
            empresa é essencial. É aí que entramos em cena. Com nossa expertise
            em sistemas de gestão empresarial, estamos prontos para auxiliar
            você em todos os aspectos cruciais da administração de sua empresa.
          </p>
          <div className="check-list">
            <ul>
              <li data-aos="fade-right">
                <img src={iconCheck} alt="check" width="35" height="35" />
                Gestão fiscal e lançamentos
              </li>
              <li data-aos="fade-right">
                <img src={iconCheck} alt="check" width="35" height="35" />
                Gestão contabil
              </li>
              <li data-aos="fade-right">
                <img src={iconCheck} alt="check" width="35" height="35" />
                Gestão de ordem de serviços
              </li>
              <li data-aos="fade-right">
                <img src={iconCheck} alt="check" width="35" height="35" />
                Inteligencia de negócios
              </li>
              <li data-aos="fade-right">
                <img src={iconCheck} alt="check" width="35" height="35" />
                Vendas
              </li>
              <li data-aos="fade-right">
                <img src={iconCheck} alt="check" width="35" height="35" />
                Mobile
              </li>
              <li data-aos="fade-right">
                <img src={iconCheck} alt="check" width="35" height="35" />
                Web
              </li>
            </ul>
          </div>
        </div>
        <div className="right">
          <img src={logoGrande} alt="logo" width="600" height="700" />
        </div>
      </section>

      <Element
        className="numeros-container-global"
        // id="sobre"
        name="sobre"
      >
        <div className="detalhes-itens">
          <div className="numeros">
            <div className="numeros-container-dados">
              {numeros.map((item, index) => (
                <div key={index} data-aos="zoom-in" className="itens">
                  <p className="numero-item" data-valor-final={item.final}>
                    0
                  </p>
                  <p className="numero-nome">{item.nome}</p>
                </div>
              ))}
            </div>
            <div className="space-numeros"></div>
            <div className="titulo-numeros">
              <h2>Venha fazer parte da nossa história</h2>
            </div>
            <div className="descricao-numeros">
              <p>
                Nós somos uma jornada que se desdobra em cada cidade, estado e
                com cada cliente que atendemos. Nossa história é uma tapeçaria
                tecida com fios de compromisso, inovação e dedicação.
              </p>
            </div>
          </div>
        </div>
      </Element>

      <Element name="segmento" className="servicos">
        <div className="detalhes-servico" id="segmento">
          <h2>
            Alguns <span className="easy-color">segmentos</span> que atendemos
          </h2>
          <p>
            Nosso compromisso com a excelência nos serviços que oferecemos se
            reflete em nossa ampla gama de segmentos de clientes atendidos. Cada
            setor possui suas próprias necessidades e desafios exclusivos, e
            estamos aqui para fornecer soluções adaptadas a cada um deles.
          </p>
        </div>
        <div className="detalhes-item">
          <div className="item">
            <a href="/varejo-atacado">
              <img src={varejo} alt="varejo e atacado" />
              <p>Varejo e Atacado</p>
            </a>
          </div>
          <div className="item">
            <a href="/restaurante">
              <img src={restaurante} alt="restaurante" />
              <p>Restaurante</p>
            </a>
          </div>
          <div className="item">
            <a href="/farmacia">
              <img src={farmacia} alt="farmácia" />
              <p>Farmácia</p>
            </a>
          </div>
          <div className="item">
            <a href="/supermercado">
              <img src={supermercado} alt="supermercado" />
              <p>Supermercado</p>
            </a>
          </div>
          <div className="item">
            <a href="/auto-peca">
              <img src={auto} alt="auto peças" />
              <p>Auto Peças</p>
            </a>
          </div>

          <div className="item">
            <a href="/padaria">
              <img src={padaria} alt="padaria" />
              <p>Padaria</p>
            </a>
          </div>

          <div className="item">
            <a href="/distribuidora">
              <img src={distribuidora} alt="distribuidora" />
              <p>Distribuidora</p>
            </a>
          </div>

          <div className="item">
            <a href="/material-para-construcao">
              <img src={construcao} alt="material para construção" />
              <p>Material para Construção</p>
            </a>
          </div>

          <div className="item">
            <a href="/otica">
              <img src={oculos} alt="ótica" />
              <p>Ótica</p>
            </a>
          </div>
        </div>
      </Element>
    </>
  );
};

export default Home;
